<template>
  <div class="page">
     <van-nav-bar
      class="backBox"
      title="我的账单"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="detail_box">
      <div class="detail_box_header" v-if="detailType === 2">
        <span class="detail_box_header_num">{{ detailInfo.vh_p_number }}</span>
        <span class="detail_box_header_num">月卡续费</span>
        <span class="detail_box_header_money">-{{ detailInfo.paidfee / 100 }}</span>
        <span class="detail_box_header_done">交易成功</span>
      </div>
      <div v-if="detailType === 1">
        <div class="detail_item">
          <span class="detail_item_tleft">车场全称</span>
          <span class="detail_item_tright">银湖车场</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">停车时长</span>
          <span class="detail_item_tright"
            >{{
              detailInfo.parked_time ? detailInfo.parked_time.seconds : ''
            }}s</span
          >
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">入场时间</span>
          <span class="detail_item_tright">{{
            detailInfo.in_time ? detailInfo.in_time : ''
          }}</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">出场时间</span>
          <span class="detail_item_tright">{{
            detailInfo.out_time ? detailInfo.out_time : ''
          }}</span>
        </div>
        <div class="detail_item">
          <!-- todo 该接口没有order_type字段 -->
          <span class="detail_item_tleft">付款方式</span>
          <span class="detail_item_tright" wx:if="{{}}">微信</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">支付时间</span>
          <span class="detail_item_tright">{{ formatDate(detailInfo.paytime) }}</span>
        </div>
      </div>

      <!-- 月卡续费 -->
      <div v-if="detailType === 2">
        <div class="detail_item">
          <span class="detail_item_tleft">付款方式</span>
          <span class="detail_item_tright">微信</span>
        </div>
        <div class="detail_item">
          <span class="detail_item_tleft">支付时间</span>
          <span class="detail_item_tright">2022</span>
        </div>
      </div>
    </div>
    <!-- 照片参考h5 另外接口 -->

      <div class="img_box" v-if="detailType === 1">
        <div class="img_box_in_out">
          <span class="img_box_tit">入场照片</span>
          <img
            src="../../../assets/img/fapiaojilu2@3x.png"
            class="img_box_img"
          />
        </div>

        <div class="img_box_in_out">
          <span class="img_box_tit">出场照片</span>
          <div class="outImg">
            <img
              src="../../../assets/img/fapiaojilu2@3x.png"
              class="img_box_img"
            />
          </div>
        </div>
      </div>

    <div class="detail_footer" v-if="detailType === 1">
      <span class="question">对订单有疑问</span>
      <div class="question_tit">
        <span class="question_btn">联系客服</span>
        <!-- <van-icon name="arrow" color="#999999" /> -->
        <img
          src="../../../assets/img/rightjt@2x.png"
          class="question_icon"
        />
      </div>
    </div>
  </div>
</template>
<script>
import * as common from '../../../api/common'
export default {
  props: ['type', 'item'],
  data () {
    return {
      detailInfo: {},
      detailType: null
    }
  },
  mounted () {
    this.detailInfo = JSON.parse(decodeURIComponent(this.item))
    this.detailType = Number(this.type)
    console.log('Type:', this.detailType)
    console.log('Item:', this.detailInfo)
    // console.log(this.$route.query)
    // console.log(this.$route.params)
    // // const detailInfo = this.$route.query.data
    // const detailInfo = this.$route.params.data
    // this.detailInfo = detailInfo
    // // this.detailType = this.$route.query.type
    // this.detailType = this.$route.params.type
    // console.log('Detail', this.detailInfo)
    // console.log('Detailtype', this.detailType)
  },
  computed: {

  },
  methods: {
    formatDate (date) {
      return date ? common.timeFormat(date, 'yyyy-MM-dd hh:mm:ss') : ''
    },
    onClickLeft () {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="scss" scoped>
.page{
  // background-color: #F8F7FC;
}
.detail_box {
  background-color: rgba(255, 255, 255, 1);
  width: 3.21rem;
  align-self: center;
  margin-top: 0.16rem;
  display: flex;
  flex-direction: column;
  padding: .08rem .11rem .13rem .11rem;
  .detail_box_header {
    display: flex;
    flex-direction: column;
    margin: 0 1.29rem 0 1.23rem;
    .detail_box_header_num {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 0.2rem;
    }
    .detail_box_header_money {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 0.18rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 0.25rem;
      align-self: center;
      margin-top: .02rem;
    }
    .detail_box_header_done {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: .12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: center;
      white-space: nowrap;
      line-height: 0.17rem;
      margin: .02rem .1rem 0 .11rem;
    }

    .detail_item_tleft {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }

  .detail_item {
    width: 3.21rem;
    margin-top: 0.16rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .detail_item_tleft {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: .14rem;
    }
    .detail_item_tright {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
  }
}

.img_box {
  width: 3.43rem;
  height: 5.04rem;
  align-self: center;
  margin-top: 0.16rem;
  display: flex;
  flex-direction: column;
  .img_box_in_out {
    background-color: rgba(255, 255, 255, 1);
    margin-bottom: 0.16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .12rem;
    .img_box_tit {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
      /* margin-right: 388rpx; */
    }
    .img_box_img {
      width: 2.5rem;
      height: 1.89rem;
      margin-top: .04rem;
    }
  }
}

.detail_footer {
  background-color: rgba(255, 255, 255, 1);
  width: 3.21rem;
  align-self: center;
  margin-top: .13rem;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: .09rem .11rem .09rem .11rem;
  .question {
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 1);
    font-size: 0.16rem;
    font-family: PingFangSC-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 0.22rem;
  }
  .question_tit {
    width: 0.7rem;
    margin-bottom: .02rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .question_btn {
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: .14rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.2rem;
    }
    .question_icon {
      width: .09rem;
      height: .15rem;
      margin: .03rem 0 .02rem 0;
    }
  }
}
</style>
