var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('van-nav-bar',{staticClass:"backBox",attrs:{"title":"我的账单","right-text":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"detail_box"},[(_vm.detailType === 2)?_c('div',{staticClass:"detail_box_header"},[_c('span',{staticClass:"detail_box_header_num"},[_vm._v(_vm._s(_vm.detailInfo.vh_p_number))]),_c('span',{staticClass:"detail_box_header_num"},[_vm._v("月卡续费")]),_c('span',{staticClass:"detail_box_header_money"},[_vm._v("-"+_vm._s(_vm.detailInfo.paidfee / 100))]),_c('span',{staticClass:"detail_box_header_done"},[_vm._v("交易成功")])]):_vm._e(),(_vm.detailType === 1)?_c('div',[_vm._m(0),_c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("停车时长")]),_c('span',{staticClass:"detail_item_tright"},[_vm._v(_vm._s(_vm.detailInfo.parked_time ? _vm.detailInfo.parked_time.seconds : '')+"s")])]),_c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("入场时间")]),_c('span',{staticClass:"detail_item_tright"},[_vm._v(_vm._s(_vm.detailInfo.in_time ? _vm.detailInfo.in_time : ''))])]),_c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("出场时间")]),_c('span',{staticClass:"detail_item_tright"},[_vm._v(_vm._s(_vm.detailInfo.out_time ? _vm.detailInfo.out_time : ''))])]),_vm._m(1),_c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("支付时间")]),_c('span',{staticClass:"detail_item_tright"},[_vm._v(_vm._s(_vm.formatDate(_vm.detailInfo.paytime)))])])]):_vm._e(),(_vm.detailType === 2)?_c('div',[_vm._m(2),_vm._m(3)]):_vm._e()]),(_vm.detailType === 1)?_c('div',{staticClass:"img_box"},[_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.detailType === 1)?_c('div',{staticClass:"detail_footer"},[_c('span',{staticClass:"question"},[_vm._v("对订单有疑问")]),_vm._m(6)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("车场全称")]),_c('span',{staticClass:"detail_item_tright"},[_vm._v("银湖车场")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("付款方式")]),_c('span',{staticClass:"detail_item_tright",attrs:{"wx:if":"{{}}"}},[_vm._v("微信")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("付款方式")]),_c('span',{staticClass:"detail_item_tright"},[_vm._v("微信")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail_item"},[_c('span',{staticClass:"detail_item_tleft"},[_vm._v("支付时间")]),_c('span',{staticClass:"detail_item_tright"},[_vm._v("2022")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box_in_out"},[_c('span',{staticClass:"img_box_tit"},[_vm._v("入场照片")]),_c('img',{staticClass:"img_box_img",attrs:{"src":require("../../../assets/img/fapiaojilu2@3x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box_in_out"},[_c('span',{staticClass:"img_box_tit"},[_vm._v("出场照片")]),_c('div',{staticClass:"outImg"},[_c('img',{staticClass:"img_box_img",attrs:{"src":require("../../../assets/img/fapiaojilu2@3x.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question_tit"},[_c('span',{staticClass:"question_btn"},[_vm._v("联系客服")]),_c('img',{staticClass:"question_icon",attrs:{"src":require("../../../assets/img/rightjt@2x.png")}})])
}]

export { render, staticRenderFns }